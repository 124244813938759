<template>
    <div>
        <div class="tit">
            <img src="../../assets/mobile/downsan.png" alt="">
            <span style="margin-left:10px">以下是常见问题答案</span>
        </div>
        <div class="text">若无法解决您的问题，请<span style="color:#3F8CFF" @click="centerDialogVisible=true">联系我们</span>，我们将为您服务。</div>
        <div class="list_box" v-for="item in list" :key="item.value">
            <div class="ask">{{item.q}}</div>
            <div class="answer">{{item.a}}</div>
        </div>
        <el-dialog :visible.sync="centerDialogVisible" custom-class="dialogClass" width="80%" center top="30%">
	   	<template #title>
	   		<div class='titleZise'>点击联系客服</div>
	   	</template>
	   	<div style="text-align:center;">
	   		<img src="../../assets/image/kefu.png" alt="">
	   		<div style="text-align:center;">
	   			<a href="https://t.me/linglongkeji" target="_blank">
	   				<p style="color:#3F8CFFFF;">Telegram: linglongkeji</p>
	   			</a>
	   		</div>
	   	</div>
	   </el-dialog> 
    </div>
</template>
<script>
export default {
    data(){
        return{
			centerDialogVisible: false,
            list:[
                {
                    q:'1、我可以在不同设备登录使用灵龙翻译助理吗?',
                    a:'是的，您可以在不同的电脑设备上登录使用灵龙翻译助理，登录账号之后，您的所有数据将同步到该台设备上。',
                    value:1,
                },
                {
                    q:'2、灵龙翻译助理安全吗?',
                    a:'当然，灵龙翻译助理无法查看，也无法保存您的任何聊天数据，而且我们针对各个端口做了加密处理，用保护您的个人隐私。',
                    value:2,
                },
                {
                    q:'3、灵龙翻译助理支持哪些社交软件?',
                    a:'灵龙翻译助理聚合了WhatsApp、Line、Telegram、Zalo、Facebook等多款海外热门社交软件，其他软件也将在后续的更新中不断添加进来。',
                    value:3,
                },
                {
                    q:'4、翻译的语言准确吗?',
                    a:'我们提供了专业的语言翻译功能，为了提高翻译的精准度，在使用过程中，请您尽量使用官方的，标准的表达方式，避免使用地方性、区域性的表达方式。',
                    value:4,
                }
            ]
        }
    }
}
</script>
<style scoped>
.tit{
    font-size: 21px;
    font-family: FZDHTJW--GB1-0, FZDHTJW--GB1;
    font-weight: bold;
    color: #252D43;
    line-height: 40px;
    margin-top: 35px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}
.text{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6D7795;
    line-height: 22px;
    text-align: left;
    margin:5px 15px 30px;
}
.list_box{
    width: 82%;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 0px rgba(168,225,255,0.5);
    border-radius: 8px;
    padding: 10px 20px;
    margin: 20px auto;
}
.ask{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #252D43;
    line-height: 24px;
    text-align: left;

}
.answer{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6D7795;
    line-height: 24px;
    text-align: left;
    margin: 10px 0 0px 22px;
}
</style>